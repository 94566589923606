import { uploadImage } from '@/shared/api-modules/application.api';
import { IMAGE_VALIDATION_ERRORS, IMAGE_VALIDATION_RULES, AVAILABLE_IMAGE_MEDIA_TYPES } from '@/shared/const';
import { reactive } from '@vue/composition-api';
class ImageModel {
    _state = reactive({
        id: null,
        uuid: null,
        dataURI: null,
        fileContainer: null,
        url: null,
        advertised: null,
        error: null,
        uploading: false,
        entityType: null
    });
    constructor(props): void {
        this._state.entityType = props ? props.type : null;
        this._state.advertised = false;
    }
    get error() {
        return this._state.error;
    }
    set error(value): void {
        this._state.error = value;
    }
    get file() {
        return this._state.fileContainer;
    }
    set file(value) {
        if (!AVAILABLE_IMAGE_MEDIA_TYPES.includes(value.type)) {
            this.error = IMAGE_VALIDATION_ERRORS.UNAVAILABLE_FORMAT;
        }
        if (value.size > IMAGE_VALIDATION_RULES.MAX_SIZE) {
            this.error = IMAGE_VALIDATION_ERRORS.TOO_BIG;
        }
        this._state.fileContainer = value;
        const reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
            this._state.dataURI = reader.result;
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                if (image.width < IMAGE_VALIDATION_RULES.MIN_WIDTH) {
                    this.error = IMAGE_VALIDATION_ERRORS.WRONG_SIZE;
                }
                if (image.height < IMAGE_VALIDATION_RULES.MIN_HEIGHT) {
                    this.error = IMAGE_VALIDATION_ERRORS.WRONG_SIZE;
                }
                if (!this.error) {
                    const fileModel = new FormData();
                    fileModel.append('image', value);
                    fileModel.append('entity_type', this._state.entityType);
                    this._state.uploading = true;
                    uploadImage(fileModel).then(response => {
                        this._state.id = response.image_id;
                        this._state.uploading = false;
                    }, error => {
                        this._state.uploading = false;
                        console.log('Uploading error', error);
                    });
                }
            };
        };
        reader.onerror = function err(error) {
            console.log('Image loading error: ', error);
        };
    }
    get url() {
        return this._state.url;
    }
    set url(value): void {
        this._state.url = value;
    }
    get src() {
        return this._state.dataURI || this.url;
    }
    get advertised() {
        return this._state.advertised;
    }
    set advertised(value): void {
        this._state.advertised = value;
    }
    get id() {
        return this._state.id;
    }
    set id(value): void {
        this._state.id = value;
    }
    get uuid() {
        return this._state.uuid;
    }
    set uuid(value): void {
        this._state.uuid = value;
    }
    get uploading() {
        return this._state.uploading;
    }
    set uploading(value): void {
        this._state.uploading = value;
    }
}
export default ImageModel;
