var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"multiple-photo-selector-container"},[(_vm.errorMessage || _vm.error)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage || _vm.error)+" ")]):_vm._e(),_c('div',{class:{ 'multiple-photo-selector': true },on:{"dragleave":_vm.dragLeave,"drop":_vm.drop,"dragover":_vm.dragOver}},[_c('input',{ref:"nativeInput",attrs:{"name":"photoInput","type":"file","multiple":"multiple","hidden":"","accept":_vm.availableImageExtensions},on:{"change":_vm.imagesSelected}}),(!_vm.images.length)?_c('div',{staticClass:"bg"},[_c('img',{attrs:{"src":"/img/common/bw_bg.png","alt":"bg"}})]):_vm._e(),(_vm.images.length)?_c('div',{staticClass:"images-container"},[_c('div',{class:{
          advertised: true,
          dragover: _vm.advertisedDragover,
          error: _vm.advertisedImage ? _vm.advertisedImage.error : false
        },style:({
          'background-image': ("url(" + (_vm.advertisedImage ? _vm.advertisedImage.src : '') + ")")
        }),on:{"dragleave":_vm.advertisedDragLeave,"drop":_vm.advertisedDrop,"dragover":_vm.advertisedDragOver}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('components.multiple_photo_selector.label_advertised')))]),(_vm.advertisedImage ? _vm.advertisedImage.uploading : false)?_c('div',{staticClass:"loading"}):_vm._e(),_c('WMButton',{staticClass:"remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeImage(_vm.advertisedImage)}}},[_c('i',{staticClass:"fa-solid fa-trash fa-sm"})])],1),_c('div',{staticClass:"images"},[(_vm.images.length > 1)?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t( 'components.multiple_photo_selector.label_drag_and_drop_advertised' ))+" ")]):_vm._e(),_vm._l((_vm.gridSize),function(rowValue,rowIndex){return _c('div',{key:rowIndex,staticClass:"image-row"},_vm._l((_vm.gridSize),function(colValue,colIndex){return _c('div',{key:colIndex,class:{
              image: true,
              drag: _vm.findNonAdvertisedImage(rowIndex, colIndex),
              border: !_vm.findNonAdvertisedImage(rowIndex, colIndex),
              error: _vm.findNonAdvertisedImage(rowIndex, colIndex)
                ? _vm.findNonAdvertisedImage(rowIndex, colIndex).error
                : false
            },style:({
              'background-image': ("url(" + (_vm.findNonAdvertisedImage(rowIndex, colIndex)
                  ? _vm.findNonAdvertisedImage(rowIndex, colIndex).src
                  : '') + ")")
            }),attrs:{"id":_vm.findNonAdvertisedImage(rowIndex, colIndex)
                ? _vm.findNonAdvertisedImage(rowIndex, colIndex).uuid
                : '',"draggable":_vm.findNonAdvertisedImage(rowIndex, colIndex)},on:{"dragstart":_vm.onImageDragStart,"drop":function($event){return _vm.onImageDrop($event, rowIndex, colIndex)}}},[(_vm.findNonAdvertisedImage(rowIndex, colIndex))?_c('WMButton',{staticClass:"remove remove-small",attrs:{"type":"button"},on:{"click":function($event){_vm.removeImage(_vm.findNonAdvertisedImage(rowIndex, colIndex))}}},[_c('i',{staticClass:"fa-solid fa-trash fa-xs"})]):_vm._e(),(
                _vm.findNonAdvertisedImage(rowIndex, colIndex)
                  ? _vm.findNonAdvertisedImage(rowIndex, colIndex).uploading
                  : false
              )?_c('div',{staticClass:"loading"}):_vm._e()],1)}),0)})],2)]):_vm._e(),_c('WMButton',{attrs:{"disabled":_vm.images.length >= 10,"color":"accent","icon-class-name":"fa-solid fa-upload fa-xs"},on:{"click":_vm.selectPhotos}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.mainButtonLabel))])]),(_vm.images.length < 10)?_c('p',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t('components.multiple_photo_selector.label_drag_and_drop_info'))+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }