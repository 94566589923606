/**
 * Add default predefined options to request.
 * @param {object} options Reqeust options.
 */
export default function (options) {
    return {
        mode: 'cors',
        credentials: 'same-origin',
        redirect: 'follow',
        referrer: 'no-referrer',
        headers: {
            'Content-Type': 'application/json'
        },
        ...options
    };
}
