import { defineComponent } from '@vue/composition-api';
import QuestionPopover from '@/uikit/QuestionPopover.vue';
export default defineComponent({
    components: {
        QuestionPopover
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        iconClasses: {
            type: Array,
            default: () => []
        },
        description: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        simple: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        popover: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { emit } = context;
        const onChange = (value) => {
            if (!props.disabled) {
                emit('input', value);
                emit('click');
            }
        };
        return { onChange };
    }
});
