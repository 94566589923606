import addBaseURL from '@/api/base/addBaseURL';
import addDefaultOptions from '@/api/base/addDefaultOptions';
import { getStorageToken } from '@/Authentication/utils/storage';
import { refreshAuthToken } from '@/Authentication/utils/token';
/**
 * Make a request.
 * @param {string} url Route path.
 * @param {object} options Request options.
 */
export default async function (url, options) {
    const request = new Request(addBaseURL(url));
    const response = await fetch(request, addDefaultOptions(options));
    if (response.status === 401 && process.browser) {
        let tokens;
        const storeToken = getStorageToken();
        if (!storeToken) {
            try {
                tokens = await refreshAuthToken();
            }
            catch (error) {
                if (error) {
                    throw error;
                }
            }
        }
        if (tokens || storeToken) {
            options.headers.Authorization = tokens.accessToken ?? storeToken;
            return await fetch(request, addDefaultOptions(options));
        }
    }
    return response;
}
