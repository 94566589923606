import { getStorageToken } from '@/Authentication/utils/storage';
/**
 * Add authorization header to options.
 * @param {object} options Request options.
 */
export default async function (options) {
    const opt = options || Object.create(null);
    opt.headers = opt.headers || Object.create(null);
    const token = await getStorageToken();
    if (token) {
        opt.headers.Authorization = `Bearer ${token}`;
    }
    return opt;
}
